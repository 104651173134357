import React, { useState } from "react";
import { Radio, Input, Space } from "antd";
import "../asset/css/confirm_purchase.scss";
import { orderGood } from "../api/api";
const ConfirmPurchase = (props) => {
  const PayType = {
    1: "BANKPAY",
    2: "ALIPAY",
    3: "WECHATPAY",
  };
  const { model } = props;
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    console.log(e);
    setValue(e.target.value);
  };

  const buyButtonHandler = (e) => {
    orderGood({
      goodId: model.goods_id,
      payType: PayType[value],
    });
  };
  return (
    <div className="buy-panel">
      <h2>选择支付方式</h2>
      <h3>{model.goods_info}</h3>
      <div className="panel-charged">
        <h3> {(model.amount * (1 - model.discount)) / 100}</h3>
        <p>{model.amount_type}</p>
      </div>
      <div>
        <Radio.Group onChange={onChange.bind(this)} value={value}>
          <Space direction="vertical">
            {/* <Radio value={1}>银行卡支付</Radio> */}
            <Radio value={2}>支付宝支付</Radio>
            {/* <Radio value={3}>微信支付</Radio> */}
          </Space>
        </Radio.Group>
      </div>
      <button className="confirm-button" onClick={buyButtonHandler.bind(this)}>
        确认购买
      </button>
    </div>
  );
};

export default ConfirmPurchase;
