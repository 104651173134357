import React from "react";
import { connect } from "react-redux";
import Login from "../components/login";
import ConfirmPurchase from "../components/confirm_purchase";
import Register from "../components/register";

const CustomModal = (props) => {
  const { showModal, modalType, model } = props;

  const modalContent = () => {
    if (modalType === "BUY") {
      return <ConfirmPurchase model={model}></ConfirmPurchase>;
    } else if (modalType === "LOGIN") {
      return <Login></Login>;
    } else if (modalType === "REGISTER") {
      return <Register></Register>;
    }
  };

  return showModal ? (
    <div className={"custom-modal"}>{modalContent()}</div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    showModal: state.showModal,
    modalType: state.modalType,
    model: state.model,
  };
};

export default connect(mapStateToProps)(CustomModal);
